(function ($) {
    $(document).ready(function () {

        // disable enter key for form fields
        $('.QuestionnaireReportsForm input, .QuestionnaireReportsForm select').not(':input[type=button], :input[type=submit], :input[type=reset]').keypress(function (event) {
            if (event.keyCode == 13) {
                event.preventDefault();
            }
        });

        // handle 'other' > 'please specify' checkbox/radio and text field
        if ($('.field.js-select-other + .field.js-field-other-specify').length !== 0) {
            function updateOther()
            {
                if ($('.field.js-select-other input[value="OtherPleaseSpecify"]').is(':checked')) {
                    $('.field.js-field-other-specify').show();
                } else {
                    $('.field.js-field-other-specify input').val('');
                    $('.field.js-field-other-specify').hide();
                }
            }
            $('.field.js-select-other input').change(function (e) {
                updateOther();
            });
            updateOther();
        }

        // waste calculator
        if ($('.js-litter-inside-shed-group').length !== 0) {

            /* Litter inside */

            function calculateLitterInside()
            {
                var length = getFloatValue('js-field-shed-length');
                var width = getFloatValue('js-field-shed-width');
                var numberOfSheds = getFloatValue('js-field-number-of-sheds');
                var litterDepth = getFloatValue('js-field-litter-depth');
                if (length > 0 && width > 0 && numberOfSheds > 0 && litterDepth > 0) {
                    $('.js-field-weight-litter-inside').val((length * width * (litterDepth / 100) * numberOfSheds / 1.8).toFixed(2));
                    $('.js-field-volume-litter-inside').val((length * width * (litterDepth / 100) * numberOfSheds).toFixed(2));
                } else {
                    $('.js-field-weight-litter-inside').val(0);
                    $('.js-field-volume-litter-inside').val(0);
                }
            }

            $('.js-field-shed-length, .js-field-shed-width, .js-field-number-of-sheds, .js-field-litter-depth').change(function (e) {
                calculateLitterInside();
                calculateTotal();
            });

            /* Litter outside */

            function calculateLitterOutsideVolume()
            {
                var weight = getFloatValue('js-field-weight-litter-outside-sheds');
                if (weight > 0) {
                    $('.js-field-volume-litter-outside-sheds').val((weight * 1.8).toFixed(2));
                } else {
                    $('.js-field-volume-litter-outside-sheds').val(0);
                }
            }

            $('.js-field-weight-litter-outside-sheds').change(function (e) {
                calculateLitterOutsideVolume();
                calculateTotal();
            });

            function calculateLitterOutsideWeight()
            {
                var volume = getFloatValue('js-field-volume-litter-outside-sheds');
                if (volume > 0) {
                    $('.js-field-weight-litter-outside-sheds').val((volume / 1.8).toFixed(2));
                } else {
                    $('.js-field-weight-litter-outside-sheds').val(0);
                }
            }

            $('.js-field-volume-litter-outside-sheds').change(function (e) {
                calculateLitterOutsideWeight();
                calculateTotal();
            });

            /* Feed */

            function calculateFeedVolume()
            {
                var weight = getFloatValue('js-field-weight-feed');
                if (weight > 0) {
                    $('.js-field-volume-feed').val((weight * 1.45).toFixed(2));
                } else {
                    $('.js-field-volume-feed').val(0);
                }
            }

            $('.js-field-weight-feed').change(function (e) {
                calculateFeedVolume();
                calculateTotal();
            });

            function calculateFeedWeight()
            {
                var volume = getFloatValue('js-field-volume-feed');
                if (volume > 0) {
                    $('.js-field-weight-feed').val((volume / 1.45).toFixed(2));
                } else {
                    $('.js-field-weight-feed').val(0);
                }
            }

            $('.js-field-volume-feed').change(function (e) {
                calculateFeedWeight();
                calculateTotal();
            });

            /* Manure inside */

            function calculateManureInsideVolume()
            {
                var weight = getFloatValue('js-field-weight-manure-in-sheds');
                if (weight > 0) {
                    $('.js-field-volume-manure-in-sheds').val((weight * 1).toFixed(0));
                } else {
                    $('.js-field-volume-manure-in-sheds').val(0);
                }
            }

            $('.js-field-weight-manure-in-sheds').change(function (e) {
                calculateManureInsideVolume();
                calculateTotal();
            });

            function calculateManureInsideWeight()
            {
                var volume = getFloatValue('js-field-volume-manure-in-sheds');
                if (volume > 0) {
                    $('.js-field-weight-manure-in-sheds').val((volume / 1).toFixed(0));
                } else {
                    $('.js-field-weight-manure-in-sheds').val(0);
                }
            }

            $('.js-field-volume-manure-in-sheds').change(function (e) {
                calculateManureInsideWeight();
                calculateTotal();
            });

            /* Eggs and trays */

            function calculateEggsAndTraysVolume()
            {
                var pallets = getFloatValue('js-field-pallets-per-farm');
                if (pallets > 0) {
                    $('.js-field-volume-eggs-and-trays').val((pallets * 1).toFixed(0));
                } else {
                    $('.js-field-volume-eggs-and-trays').val(0);
                }
            }

            $('.js-field-pallets-per-farm').change(function (e) {
                calculateEggsAndTraysVolume();
                calculateTotal();
            });
            function calculateEggsAndTraysWeight()
            {
                var eggs = getIntValue('js-field-eggs-per-tray');
                var trays = getIntValue('js-field-trays-per-pallet');
                var pallets = getIntValue('js-field-pallets-per-farm');
                var eggWeight = getIntValue('js-field-average-egg-weight');
                var trayWeight = getIntValue('js-field-average-tray-weight');
                if (eggs > 0 && trays > 0 && pallets > 0 && eggWeight > 0) {
                    $('.js-field-weight-eggs-and-trays').val(((eggs * trays * pallets * eggWeight / 1000000) + (trays * pallets * trayWeight / 1000000)).toFixed(2));
                } else {
                    $('.js-field-weight-eggs-and-trays').val(0);
                }
            }

            $('.js-field-eggs-per-tray, .js-field-trays-per-pallet, .js-field-pallets-per-farm, .js-field-average-egg-weight, .js-field-average-tray-weight').change(function (e) {
                calculateEggsAndTraysWeight();
                calculateTotal();
            });

            /* Poultry */

            function calculatePoultry()
            {
                var poultry = getIntValue('js-field-number-of-poultry');
                var poultryWeight = getFloatValue('js-field-average-poultry-weight');
                if (poultry > 0 && poultryWeight > 0) {
                    $('.js-field-weight-poultry').val((poultry * poultryWeight / 1000).toFixed(2));
                    $('.js-field-volume-poultry').val((poultry * poultryWeight / 1000 / 3.2).toFixed(2));
                } else {
                    $('.js-field-weight-poultry').val(0);
                    $('.js-field-volume-poultry').val(0);
                }
            }

            $('.js-field-number-of-poultry, .js-field-average-poultry-weight').change(function (e) {
                calculatePoultry();
                calculateTotal();
            });

            /* Total */

            function calculateTotal()
            {

                var litterInsideWeight = getFloatValue('js-field-weight-litter-inside').toFixed(2);
                $('#js-result-weight-litter-in-sheds').html(litterInsideWeight);
                var litterInsideVolume = getFloatValue('js-field-volume-litter-inside').toFixed(2);
                $('#js-result-volume-litter-in-sheds').html(litterInsideVolume);

                var manureInsideWeight = getFloatValue('js-field-weight-manure-in-sheds').toFixed(2);
                $('#js-result-weight-manure-in-sheds').html(manureInsideWeight);
                var manureInsideVolume = getFloatValue('js-field-volume-manure-in-sheds').toFixed(2);
                $('#js-result-volume-manure-in-sheds').html(manureInsideVolume);

                var litterOutsideWeight = getFloatValue('js-field-weight-litter-outside-sheds').toFixed(2);
                $('#js-result-weight-litter-outside-sheds').html(litterOutsideWeight);
                var litterOutsideVolume = getFloatValue('js-field-volume-litter-outside-sheds').toFixed(2);
                $('#js-result-volume-litter-outside-sheds').html(litterOutsideVolume);

                var feedWeight = getFloatValue('js-field-weight-feed').toFixed(2);
                $('#js-result-weight-feed').html(feedWeight);
                var feedVolume = getFloatValue('js-field-volume-feed').toFixed(2);
                $('#js-result-volume-feed').html(feedVolume);

                var eggsAndTraysWeight = getFloatValue('js-field-weight-eggs-and-trays').toFixed(2);
                $('#js-result-weight-eggs-and-trays').html(eggsAndTraysWeight);
                var eggsAndTraysVolume = getFloatValue('js-field-volume-eggs-and-trays').toFixed(2);
                $('#js-result-volume-eggs-and-trays').html(eggsAndTraysVolume);

                var poultryWeight = getFloatValue('js-field-weight-poultry').toFixed(2);
                $('#js-result-weight-poultry').html(poultryWeight);
                var poultryVolume = getFloatValue('js-field-volume-poultry').toFixed(2);
                $('#js-result-volume-poultry').html(poultryVolume);

                // total
                var totalWeight = (parseFloat(litterInsideWeight) + parseFloat(manureInsideWeight) + parseFloat(litterOutsideWeight) + parseFloat(feedWeight) + parseFloat(eggsAndTraysWeight) + parseFloat(poultryWeight)).toFixed(2);
                $('#js-result-total-weight').html(totalWeight);
                $('.js-field-total-weight').val(totalWeight);

                var totalVolume = (parseFloat(litterInsideVolume) + parseFloat(manureInsideVolume) + parseFloat(litterOutsideVolume) + parseFloat(feedVolume) + parseFloat(eggsAndTraysVolume) + parseFloat(poultryVolume)).toFixed(2);
                $('#js-result-total-volume').html(totalVolume);
                $('.js-field-total-volume').val(totalVolume);

            }

            calculateLitterInside();
            calculateLitterOutsideVolume();
            calculateLitterOutsideWeight();
            calculateManureInsideVolume();
            calculateManureInsideWeight();
            calculateFeedWeight();
            calculateFeedVolume();
            calculateEggsAndTraysWeight();
            calculateEggsAndTraysVolume();
            calculatePoultry();
            calculateTotal();
        }

        function getIntValue(classname)
        {
            var val = $('input.' + classname + ',select.' + classname + ',textarea.' + classname).first().val();
            var parsed = parseInt(val.replaceAll(',', ''));
            if (isNaN(parsed)) {
                return 0; }
            return parsed;
        }
        function getFloatValue(classname)
        {
            var val = $('input.' + classname + ',select.' + classname + ',textarea.' + classname).first().val();
            var parsed = parseFloat(val.replaceAll(',', ''));
            if (isNaN(parsed)) {
                return 0.0; }
            return parsed;
        }
    });
}(jQuery));
